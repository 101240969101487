import { googleLogout } from '@react-oauth/google'
import axios from 'axios'
import LogRocket from 'logrocket'
import React, { createContext, useState, useEffect, useContext } from 'react'

import { FeatureContext } from './FeatureContext'
import { GlobalContext } from './GlobalContext'
import { AUTH_DASHBOARD_URL, NODE_URL } from '../constants'

export const UserContext = createContext(null)

export const UserContextProvider = props => {
  const [role, setRole] = useState()
  const [lgLoaded, setLgLoaded] = useState(false)
  const { idmToken, setIdmToken } = useContext(GlobalContext)
  const { getFeatureFlags, clearCookies } = useContext(FeatureContext)

  const userContextConfig = {
    cacheTime: 1000 * 60 * 2, // saved for 2 minute
    staleTime: 1000 * 60 * 5, // saved for 5 minute
    refetchOnWindowFocus: false,
  }

  useEffect(() => {
    setupAxiosInterceptor(idmToken)
  }, [idmToken])

  const setupAxiosInterceptor = idmToken => {
    axios.interceptors.request.use(
      function (config) {
        if (!config.headers.Authorization) {
          if (idmToken) {
            config.headers.Authorization = `Bearer ${idmToken}`
          } else {
            config.headers.Authorization = undefined
          }
        }
        config.headers['X-Experimental-Feature-Flag'] = getFeatureFlags()?.join(',')

        return config
      },
      function (error) {
        return Promise.reject(error)
      }
    )
  }

  const fetchCurrentUserData = async () => {
    const { data } = await axios
      .get(`${NODE_URL}/me`, {
        headers: {
          Authorization: `Bearer ${idmToken}`,
          'X-Experimental-Feature-Flag': getFeatureFlags()?.join(','),
        },
      })
      .catch(err => {
        if (err?.response?.status === 401 && idmToken) {
          logoutHandler()
        }
        return null
      })

    // Make sure that the experimental feature flag matches the user's feature flag set in metadata (which is the source of truth)
    const userFeatureFlags = data.extraData.experimentalFeatures || undefined
    const cookieFeatureFlags = getFeatureFlags()?.join(',') || undefined
    if (userFeatureFlags !== cookieFeatureFlags) {
      logoutHandler()
    }

    // Record brand user sessions on production with LogRocket
    if (!lgLoaded && data.role !== 'administrator' && process.env.NODE_ENV === 'production') {
      LogRocket.init('zbp1lp/creator-co', {
        // Sanitize the request body to avoid sending sensitive data to LogRocket (such as passwords)
        requestSanitizer: request => {
          if (request.url.toLowerCase().indexOf('/login') !== -1) request.body.password = null
          return request
        },
      })

      // Identify the user
      LogRocket.identify(data.id, {
        name: `${data.firstName} ${data.lastName}`,
        email: data.email,
        // User traits (can be used to search sessions)
        type: data.role,
      })

      // Prevent LogRocket from initializing multiple times
      setLgLoaded(true)
    }

    return data
  }

  const logoutHandler = () => {
    // TODO: fix redirect_url mismatch error https://creatorco.atlassian.net/browse/ENG-1055
    // logout of IDM
    // try {
    //   idm.authenticator.logout()
    // } catch (err) {
    //   console.log(err)
    // }
    clearCookies()
    setIdmToken(null)

    // log out of google
    googleLogout()

    window.location.href = AUTH_DASHBOARD_URL
  }

  const userContextValues = {
    idmToken,
    role,
    setRole,
    logoutHandler,
    fetchCurrentUserData,
    userContextConfig,
  }

  return <UserContext.Provider value={userContextValues}>{props.children}</UserContext.Provider>
}
