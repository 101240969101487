import IDM from '@creator.co/module-identity-client'
import axios from 'axios'
import React, { createContext, useContext, useEffect, useState } from 'react'
import Cookies from 'universal-cookie'

import { FeatureContext } from './FeatureContext'
import { AUTH_DASHBOARD_DOMAIN, IDM_URL, NODE_URL } from '../constants'

export const GlobalContext = createContext(null)

export const GlobalContextProvider = props => {
  const cookies = new Cookies()
  const [menuExpanded, setMenuExpanded] = useState(false)
  const [idmToken, setIdmToken] = useState()
  const [idm, setIdm] = useState()
  const toggleMenu = () => setMenuExpanded(!menuExpanded)
  const { hasFeatureFlag } = useContext(FeatureContext)

  useEffect(() => {
    const client = new IDM({
      apiEndpoint: IDM_URL,
      externalAuthDomain: AUTH_DASHBOARD_DOMAIN,
      externalAuthPath: '/login',
      externalAuth: true,
    })

    if (!hasFeatureFlag('idm')) {
      setIdmToken(cookies.get('idm_token_v1'))
      setIdm(client)
    } else {
      // Attempt to load session
      client
        .load()
        .then(async loaded => {
          console.log('IDM is logged in?', await client.isLogged(), { loaded })

          if (loaded) {
            setIdmToken(await client.session.getToken())
          }

          setIdm(client)
        })
        .catch(err => {
          console.log('IDM FAILED TO LOAD: ')
          console.error(err)
        })
    }
  }, [])

  const getCategories = async () => {
    const { data } = await axios.get(`${NODE_URL}/categories`)
    return data
  }

  const contextValues = {
    menuExpanded,
    toggleMenu,
    getCategories,
    idm,
    idmToken,
    setIdmToken,
  }

  return <GlobalContext.Provider value={contextValues}>{props.children}</GlobalContext.Provider>
}
