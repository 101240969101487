import { Button, message, notification } from 'antd'
import axios from 'axios'
import React, { createContext, useState } from 'react'
import { queryCache } from 'react-query'

import { NODE_URL } from '../constants'
import { AlertWrapper } from '../layout/creator-search/lists/CreatorListItem'

export const BrandContext = createContext(null)

export const BrandContextProvider = props => {
  const [brandId, setBrandId] = useState('')

  const fetchAffiliateLinkData = async (key, brandId) => {
    const { data } = await axios.get(`${NODE_URL}/brand/${brandId}/affiliates/links`)
    if (Array.isArray(data)) {
      return data
    } else {
      return data?.brandAffiliateLinks
    }
  }

  const fetchAffiliateAnalytics = async (key, brandId, params) => {
    const { data } = await axios.get(
      `${NODE_URL}/brands/affiliates/affiliate-analytics/${brandId}`,
      {
        params,
      }
    )

    return data
  }

  const fetchCampaigns = async (key, brandId, params) => {
    if (!brandId) return null
    const { data } = await axios.get(`${NODE_URL}/brand/${brandId}/campaigns`, {
      params,
    })
    return data.campaigns
  }

  const fetchFilterAffiliates = async (key, brandId) => {
    const { data } = await axios.get(`${NODE_URL}/brands/affiliates/filter-affiliates/${brandId}`)

    return data
  }

  const fetchActiveCampaigns = async (key, brandId) => {
    if (!brandId) return null
    const { data } = await axios.get(`${NODE_URL}/brand/${brandId}/campaigns`, {
      params: { status: 'publish' },
    })
    return data.campaigns
  }

  const fetchBrandData = async (key, brandId) => {
    if (!brandId) return null
    const { data } = await axios.get(`${NODE_URL}/brand/${brandId}`)
    return data
  }

  const fetchCreatorLists = async (key, brandId) => {
    const { data } = await axios.get(`${NODE_URL}/brand/${brandId}/creator-lists`)
    return data.lists
  }

  const fetchCreatorListsDetail = async (key, { brandId, formData }, page = 1) => {
    const { data } = await axios.get(`${NODE_URL}/brand/${brandId}/creator-lists-detail/${page}`, {
      params: formData,
    })
    return data
  }

  const fetchCreatorListItems = async (key, { brandId, creatorListId, formData }, page = 1) => {
    const { data } = await axios.get(
      `${NODE_URL}/brand/${brandId}/creator-list-items/${creatorListId}/${page}`,
      {
        params: formData,
      }
    )
    return data
  }

  const updateCreatorList = async (creatorListId, formData) => {
    const { data } = await axios.post(`${NODE_URL}/creator-list-update/${creatorListId}`, formData)
    return data
  }

  const updateCreatorListItem = async (creatorListItemId, formData) => {
    const { data } = await axios.post(
      `${NODE_URL}/creator-list-item/${creatorListItemId}`,
      formData
    )
    return data
  }

  const archiveCreatorList = async creatorListId => {
    const { data } = await axios.post(`${NODE_URL}/archived-creator-lists/${creatorListId}`)
    return data
  }

  const archiveCreatorListItem = async creatorListItemId => {
    const { data } = await axios.post(`${NODE_URL}/archived-creators/${creatorListItemId}`)
    return data
  }

  const recoverCreatorListItem = async creatorListItemId => {
    const { data } = await axios.post(`${NODE_URL}/unarchived-creators/${creatorListItemId}`)
    return data
  }

  const fetchArchivedCreators = async (key, brandId, page = 1) => {
    const { data } = await axios.get(`${NODE_URL}/archived-creators/${brandId}/${page}`)
    return data
  }

  const getAudienceData = async (key, params) => {
    const { brandId, socialProfileId, socialId } = params
    const { data } = await axios.get(`${NODE_URL}/brand/${brandId}/audience-data`, {
      params: { socialProfileId, socialId },
    })
    return data
  }

  const purchaseAudienceData = async (brandId, socialProfileId, force, socialId) => {
    const { data } = await axios
      .post(`${NODE_URL}/brand/${brandId}/audience-data`, {
        socialProfileId: socialProfileId ? `${socialProfileId}` : undefined,
        forceReload: !!force,
        socialId,
      })
      .then(res => {
        queryCache.invalidateQueries('audience-data')
        queryCache.invalidateQueries('brand')
        notification.success({
          message: 'Deep Insights Purchased',
          description: (
            <AlertWrapper>
              <p>
                Report saved for <b>{res.data.report.user_profile.username}</b>. 1 credit has been
                deducted from your account.
              </p>
              <div className='buttons'>
                <Button type='primary'>
                  <a href={res.data.reportUrl} download>
                    Download
                  </a>
                </Button>
                <Button
                  type='secondary'
                  onClick={() => {
                    window.location.href = '/creator-search/deep-insights'
                  }}>
                  View All
                </Button>
              </div>
            </AlertWrapper>
          ),
          duration: 0,
        })
        return res
      })
      .catch(err => {
        const errCode = err.response?.data?.errCode
        const errMsg = err.response?.data?.err
        if (errCode === 'retry_later') {
          message.warning(
            errMsg ||
              'We are currently processing your request. Please check back in a few minutes.'
          )
        } else if (errCode === 'empty_audience_data') {
          message.warning('No audience data is available for this channel')
        } else {
          message.error(errMsg || 'An unknown error occurred')
        }
        return err
      })
    return data
  }

  const openBilling = () => {
    var chargebeeInstance = window.Chargebee.getInstance()
    chargebeeInstance.closeAll()

    chargebeeInstance.setPortalSession(() => {
      return axios.get(`${NODE_URL}/create-portal-session/${brandId}`).then(response => {
        return response.data
      })
    })

    const cbPortal = chargebeeInstance.createChargebeePortal()
    cbPortal.open({
      close() {
        chargebeeInstance.logout()
      },
    })
  }

  const fetchMessageTemplates = async (key, brandId) => {
    const { data } = await axios.get(`${NODE_URL}/brand/${brandId}/message-templates`)
    return data.messageTemplates
  }

  const fetchCreatorProfileDetails = async (key, brandId, creatorId, params) => {
    if (!creatorId) return null
    const { data } = await axios.get(
      `${NODE_URL}/brands/affiliates/affiliate-analytics/${brandId}/creator/${creatorId}`,
      {
        params,
      }
    )

    return data
  }

  const brandContextValues = {
    brandId,
    setBrandId,
    fetchAffiliateAnalytics,
    fetchAffiliateLinkData,
    fetchBrandData,
    fetchFilterAffiliates,
    fetchCampaigns,
    fetchActiveCampaigns,
    fetchCreatorLists,
    fetchCreatorListsDetail,
    fetchCreatorListItems,
    updateCreatorList,
    updateCreatorListItem,
    archiveCreatorList,
    archiveCreatorListItem,
    recoverCreatorListItem,
    fetchArchivedCreators,
    getAudienceData,
    purchaseAudienceData,
    openBilling,
    fetchMessageTemplates,
    fetchCreatorProfileDetails,
  }

  return <BrandContext.Provider value={brandContextValues}>{props.children}</BrandContext.Provider>
}
