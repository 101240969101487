import styled, { createGlobalStyle, keyframes } from 'styled-components'

export const theme = {
  // colors
  crcoLettuce: '#18D96E',
  crcoTechBlue: '#027DF0',
  crcoNeonYellow: '#DDFF00',
  crcoCoral: '#FF7661',
  crcoMidnight: '#080830',
  crcoOffWhite: '#F7F7F7',
  crcoCottonCandyBlue: '#bbdcf7',
  crcoCottonCandyPink: '#ffccd9',
  crcoLavender: '#b0a1d8',
  crcoPaleBlue: '#e9f4fc',
  crcoGrey: '#777e90',
  crcoBlack: '#23262f',

  crcoBoxShadow: '0 1px 2px 0 rgb(0 0 20 / 8%), 0 2px 4px 0 rgb(0 0 20 / 8%)',

  // breakpoints
  breakpointMobile: '480px',
  breakpointTablet: '767px',
  breakpointDesktop: '980px',
  breakpointDesktopWide: '1200px',

  scrollbar: `
    ::-webkit-scrollbar {
        height: 10px;
        width: 10px;
    }
    ::-webkit-scrollbar-track {
        background-color: #f2f2f2;
        border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: lightgray;
        border-radius: 5px;
        cursor: pointer;
    }`,
}

export const gradientAnimation = keyframes`
    0% {
        background-position: 0% 50%;
        }
    100% {
        background-position: 108% 50%;
        }
`

export const ActionMenuWrapper = styled.div`
  min-width: 150px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Campton-Medium', Verdana, sans-serif;
  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
  }
`

export default createGlobalStyle`
`
