import { Spin } from 'antd'
import React, { Suspense, useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import Base from './layout/Base'
import { Header } from './layout/Header'
import { MobileMenu } from './layout/MobileMenu'

export const SuperBase = () => {
  const DelayedFallback = () => {
    const [show, setShow] = useState(false)

    useEffect(() => {
      const timer = setTimeout(() => setShow(true), 300)
      return () => clearTimeout(timer)
    }, [])

    return show ? (
      <div className='load'>
        <Spin />
      </div>
    ) : null
  }
  return (
    <Wrapper>
      <Header />
      <MobileMenu />
      <Base>
        <Suspense fallback={<DelayedFallback />}>
          <Outlet />
        </Suspense>
      </Base>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .load {
    display: flex;
    background: #f4f4f8;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
`
